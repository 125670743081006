var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DlbzTiLsxR0v7ZZF5D-O0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/browser');

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/api\.gligle\.efficax\.team\/api/],
    }),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      mask: [
        'input[name="token"]',
        'input[name="password"]',
        'input[name="email"]',
        'input[name="code"]',
      ],
    }),
  ],

  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.05,
});
